<template>
    <div class="input-additional-options-container">
        <input-additional-options />
        <input-additional-options-time />
        <input-additional-options-cell-line />
    </div>
</template>

<script>
    import InputAdditionalOptions from "./InputAdditionalOptions";
    import InputAdditionalOptionsTime from "./InputAdditionalOptionsTime";
    import InputAdditionalOptionsCellLine from "./InputAdditionalOptionsCellLine";

    export default {
        components: {
            InputAdditionalOptionsCellLine,
            InputAdditionalOptionsTime,
            InputAdditionalOptions},
        name: "input-additional-options-container"
    }
</script>

<style scoped>
    .input-additional-options-container > div {
        margin-bottom: 0.5em;
    }
</style>