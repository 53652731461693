<template>
    <div class="heatmap-top">
        <apexchart type="heatmap" :options="options" :series="series" height="80" class="heatmap-top-apex"/>
    </div>
</template>

<script>
    import topSeries from '../landingData/topSeries'
    import options from '../chartOptions'

    const heights = {
        16: 300,
        15: 300,
        14: 300,
        13: 280,
        12: 260,
        11: 235,
        10: 220,
        9: 190,
        8: 168,
        7: 158,
        6: 138,
        5: 123,
        4: 108,
        3: 90,
        2: 78,
        1: 64
    }

    export default {
        name: "heatmap-top",
        data() {
            return {
                options: options(this, false),
            }
        },
        computed: {
            cellLines() {
                const cellLines  = this.$store.state.cellLines
                let separateCellLines = []
                for (const el of cellLines) {
                    separateCellLines = separateCellLines.concat(el.split('/'))
                }
                return separateCellLines
            },
            height() {
                return heights[this.series.length]
            },
            replicates() {
                return this.$store.state.replicates
            },
            times() {
                return this.$store.state.times
            },
            series() {
                const series = topSeries.series[this.replicates]
                const filteredSeries = series.map((el) => {
                    return {
                        ...el,
                        data: el.data.filter((d) => {
                            return (
                                (this.times.includes(d.time)) &&
                                (this.cellLines.includes(d.cellLine))
                            )
                        })
                    }
                })

                const emptyObj = {
                    'x': '',
                    'y': 1000,
                    'value': '',
                    'gene': '',
                    'time': '',
                    'cellLine': ''
                }

                let withBlanks = [];
                for (let series of filteredSeries) {
                    let newEl = {...series, data: []}
                    for (const [i, dataEl] of series.data.entries()) {
                        newEl.data.push(dataEl)
                        let isResistant = dataEl.cellLine.includes(' R');
                        let precedesSensitive = series.data[i+1] && series.data[i+1].cellLine.includes(' S')
                        if (isResistant && precedesSensitive) {
                            newEl.data.push(emptyObj)
                            newEl.data.push(emptyObj)
                        }

                        let isSensitive = dataEl.cellLine.includes(' S');
                        let precedesResistant = series.data[i+1] && series.data[i+1].cellLine.includes(' R')
                        if (isSensitive && precedesResistant) {
                            newEl.data.push(emptyObj)
                        }
                    }
                    withBlanks.push(newEl)
                }

                return withBlanks;
            }
        },
        async mounted() {
        }
    }
</script>

<style scoped>
.heatmap-top {
    border-bottom: solid 1px lightgray;
    margin-bottom: -1em;
}
    .heatmap-top-apex {
        margin-bottom: -1em;
    }
</style>