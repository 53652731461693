<template>
    <div class="heatmap-y-axis">
        <div class="y-axis-label" v-if="cellLines.includes('PEA1 S/PEA2 R')">
            PEA1 S/PEA2 R
        </div>
        <div class="y-axis-label" v-if="cellLines.includes('PEO1 S/PEO4 R')">
            PEO1 S/PEO4 R
        </div>
        <div class="y-axis-label" v-if="cellLines.includes('PEO14 S/PEO23 R')">
               PEO14 S/PEO23 R
        </div>
    </div>
</template>
<!-- ['PEA1 S/PEA2 R', 'PEO1 S/PEO4 R', 'PEO14 S/PEO23 R',] -->
<script>
    export default {
        name: "heatmap-y-axis",
        computed: {
            cellLines() {
                return this.$store.state.cellLines
            }
        }
    }
</script>

<style scoped>
    .heatmap-y-axis {
        background: lightgrey;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font-weight: bold;
        margin-bottom: -1em;
        text-align: center;
    }

    .y-axis-label {
        background: white;
        width: 10em;
    }
</style>