
function colorRange([val, hex]) {
  return {
    from: val,
    to: val,
    color: hex,
  };
}

const zscoreRanges = [
{
  from: -30,
  to: -2.75,
  color: '#00004d',
},
{
  from: -2.75,
  to: -2.5,
  color: '#003366',
},
{
  from: -2.5,
  to: -2.25,
  color: '#004c99',
},
{
  from: -2.25,
  to: -2,
  color: '#0066cc',
},
{
  from: -2,
  to: -1.75,
  color: '#0080ff',
},
{
  from: -1.75,
  to: -1.5,
  color: '#3399ff',
},
{
  from: -1.5,
  to: -1.25,
  color: '#66b2ff',
},
{
  from: -1.25,
  to: -1,
  color: '#82b6ff',
},
{
  from: -1,
  to: -0.75,
  color: '#99ccff',
},
{
  from: -0.75,
  to: -0.1,
  color: '#CCE5FF',
},
  {
  from: -0.1,
  to: 0.1,
  color: '#E8E8E8',
},
{
  from: 0.1,
  to: 0.75,
  color: '#ffcccc',
},
{
  from: 0.75,
  to: 1,
  color: '#ff9999',
},
{
  from: 1,
  to: 1.25,
  color: '#ff7777',
},

{
  from: 1.25,
  to: 1.5,
  color: '#ff6666',
},
  {
  from: 1.5,
  to: 1.75,
  color: '#ff5555',
},
{
  from: 1.75,
  to: 2,
  color: '#ff3333',
},
  {
  from: 2,
  to: 2.25,
  color: '#ff1111',
},
{
  from: 2.25,
  to: 2.5,
  color: '#ff0000',
},
{
  from: 2.5,
  to: 2.75,
  color: '#ff0000',
},
{
  from: 2.75,
  to: 3,
  color: '#E50000',
},
{
  from: 3,
  to: 30,
  color: '#990000',
},
];

const colorsRanges = {
  1000: '#ffffff', //'NA'
  1001: '#afb1b7', // Mutation 0
  1002: '#1f78b4', //Mutation 1
  1003: '#afb1b7', // LOH 0
  1004: '#1f78b4', //LOH 1
  1005: '#00b9ff', //Platinum response: sensitive
  1006: '#f77f00', //Platinum response: resistance,
  1007: '#BDBDBD',// 00Hr
  1008: '#9E9E9E',// 08Hr
  1009: "#696969",// 24Hr
  1010: '#B7FFBF', // 08-00Hr
  1011: '#00Ab08',  // 24Hr-00Hr
  1012: '#EB6534',// PEA1 S
  1013: '#FFD25A',// PEA2 R
  1014: '#0E79B2', // PEO1 S
  1015: '#BF1363', // PEO14 S
  1016: '#566E3D', // PEO23 R
  1017: '#AFD0D6', // PEO4 R
};

export default {
  ranges: Object.entries(colorsRanges).map(colorRange).concat(zscoreRanges),
};
