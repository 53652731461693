<template>
    <div class="heatmap-gene" v-if="this.series.length">
        <div class="gene-heatmap-label">
            <p><a v-if="uniprotLink"  :href="uniprotLink" target="_blank">{{ gene }}</a></p>
            <p v-if="!uniprotLink">{{ gene }}</p>
        </div>
        <apexchart type="heatmap" :options="options" :series="filteredSeries" :height="height" class="heatmap-gene-apex"/>
    </div>
</template>

<script>
    import { getSeries } from '../firebase.js'
    import options from '../chartOptions'
    import uniProtLinks from '../uniprotLinks'

    const heights = {
        30: 350,
        29: 340,
        28: 340,
        27: 340,
        26: 340,
        25: 330,
        24: 330,
        23: 330,
        22: 330,
        21: 330,
        20: 320,
        19: 320,
        18: 320,
        17: 320,
        16: 300,
        15: 300,
        14: 300,
        13: 280,
        12: 260,
        11: 235,
        10: 220,
        9: 190,
        8: 168,
        7: 158,
        6: 138,
        5: 123,
        4: 108,
        3: 90,
        2: 78,
        1: 68
    }

    const filterSeries = (series, times, cellLines) => {
        let filteredSeries = series.map((el, idx) => {
            return {
                ...el,
                data: el.data.filter((d) => {
                    return (
                            (times.includes(d.time)) &&
                            (cellLines.includes(d.cellLine))
                        )
                })
            }
        })
        const emptyObj = {
            'x': '',
            'y': 1000,
            'value': '',
            'gene': '',
            'time': '',
            'cellLine': ''
        }

        let withBlanks = [];
        for (let series of filteredSeries) {
            let newEl = {...series, data: []}
            for (const [i, dataEl] of series.data.entries()) {
                newEl.data.push(dataEl)
                let isResistant = dataEl.cellLine.includes(' R');
                let precedesSensitive = series.data[i+1] && series.data[i+1].cellLine.includes(' S')
                if (isResistant && precedesSensitive) {
                    newEl.data.push(emptyObj)
                    newEl.data.push(emptyObj)
                }

                let isSensitive = dataEl.cellLine.includes(' S');
                let precedesResistant = series.data[i+1] && series.data[i+1].cellLine.includes(' R')
                if (isSensitive && precedesResistant) {
                    newEl.data.push(emptyObj)
                }
            }
            withBlanks.push(newEl)
        }

        return withBlanks;
    }

    async function fetchSeries(replicates, gene) {
        const series = await
            getSeries({
                replicates: replicates,
                gene: gene
            })
        return series
    }

    export default {
        name: "heatmap-gene",
        props: ['gene'],
        data() {
            return {
                options: options(this),
                series: [],
                filteredSeries: []
            }
        },
        computed: {
            height() {
                return heights[this.series.length]
            },
            replicates() {
                return this.$store.state.replicates
            },
            times() {
                return this.$store.state.times
            },
            cellLines() {
                const cellLines  = this.$store.state.cellLines
                let separateCellLines = []
                for (const el of cellLines) {
                    separateCellLines = separateCellLines.concat(el.split('/'))
                }
                return separateCellLines
            },
            uniprotLink() {
                return uniProtLinks[this.gene]
            }
        },
        watch: {
            async replicates() {
                this.series = await fetchSeries(this.replicates, this.gene)
                this.filteredSeries = filterSeries(this.series, this.times, this.cellLines)
            },
            times() {
                this.filteredSeries = filterSeries(this.series, this.times, this.cellLines)
            },
            async cellLines() {
                this.filteredSeries = filterSeries(this.series, this.times, this.cellLines)
            },
        },
        async mounted() {
            this.series = await fetchSeries(this.replicates, this.gene)
            this.filteredSeries = filterSeries(this.series, this.times, this.cellLines)
        },
    }
</script>

<style scoped>
.heatmap-gene {
    border-bottom: solid 1px lightgray;
    margin-bottom: -0.9em;
}

    .gene-heatmap-label {
        font-weight: bold;
        text-align: center;
        margin-bottom: -1em;
        margin-top: 1em;
    }

        .heatmap-gene-apex {
        margin-bottom: -1em;
    }
</style>