const fixedCategories = {
    horizontal: {
        Missing: [
            ['', '#ffffff'],
        ],
    },
    vertical: {
    },
}
export default {
        categoriesHorizontal: {
            'Proteo/RNA/phospho z-score': [
                ['<-3', '#00004d'],
                ['-2', '#0066cc'],
                ['-1', '#82b6ff'],
                ['0', '#E8E8E8'],
                ['1', '#ff7777'],
                ['2', '#ff1111'],
                ['>3', '#990000'],
            ],
            'LOH': [
                  ['No', '#afb1b7'],
                  ['Yes', '#1f78b4'],
            ],
            'CNV z-score': [
                ['(-inf, -0.5]', '#00004d'],
                ['(-0.5, -0.2]', '#82b6ff'],
                ['(-0.2, 0.2]', '#E8E8E8'],
                ['(0.2, 0.5]', '#ff7777'],
                ['(0.5, +inf)', '#990000'],
            ],
            ...fixedCategories.horizontal,
        },
        categories: {
            'Platinum response': [
                ['Sensitive', '#00b9ff'],
                ['Resistant', '#f77f00'],
            ],
            'Time': [
                ['00Hr', '#BDBDBD'],
                ['08Hr', '#9E9E9E'],
                ['24Hr', '#696969'],
                ['08-00Hr', '#B7FFBF'],
                ['24Hr-00Hr', '#00Ab08'],
            ],
            ...fixedCategories.vertical,
        },
}
