<template>
    <div class="input-additional-options">
        <section>
        <div class="block">
            <p class="replicate-label">Replicates: </p>
            <b-radio v-model="replicates"
                size="is-small"
                native-value="Averaged">
                Averaged
            </b-radio>
            <b-radio v-model="replicates"
                size="is-small"
                native-value="All">
                All
            </b-radio>
        </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "input-additional-options",
        computed: {
            replicates: {
                get() {
                    return this.$store.state.replicates
                },
                set(v) {
                    this.$store.dispatch('setReplicates', v)
                }
            }
        }
    }
</script>

<style scoped>
    .input-additional-options {
        font-size: 1em;
    }

    .replicate-label {
        font-weight: bold;
        font-size: 0.9em;
    }

</style>