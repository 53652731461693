<template>
    <div class="input-pathway-selector-container">
        <p class="pathway-selector-instructions">or search pathway databases to populate gene list</p>
        <b-field>
            <b-select
                    v-model="db"
                    placeholder="Select pathway database"
                    size="is-default"
                    expanded
            >
                <option
                    v-for="option in dbs"
                    :value="option"
                    :key="option"
                >
                    {{ option }}
                </option>
            </b-select>
        </b-field>
        <b-field>
            <b-autocomplete
                :disabled="!db"
                rounded
                v-model="name"
                :data="filteredDataArray"
                :placeholder="`Search ${db || ''} pathways`"
                icon="magnify"
                clearable
                @select="option => selectedPathway = option"
                class="pathway-search"
            >
                <template slot="empty">No results found</template>
            </b-autocomplete>
        </b-field>
    </div>
</template>

<script>
    import {getPathwayList, getPathwayGenes} from "../firebase";


    export default {
        data() {
            return {
                dbs: [
                    'KEGG',
                    'Reactome',
                    'Hallmark'
                ],
                selectedPathway: null,
                pathways: [],
                name: ''
            }
        },
        computed: {
            db: {
                get() {
                    return this.$store.state.pathwayDatabase
                },
                set(v) {
                    this.$store.dispatch('setPathwayDatabase', v)
                }
            },
            filteredDataArray() {
                return this.pathways.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.name.toLowerCase()) >= 0
                })
            }
        },
        watch: {
            selectedPathway() {
                getPathwayGenes(this.db, this.selectedPathway)
                    .then((genes) => {
                        if (!genes) {
                            return
                        }
                        this.$store.dispatch('setGenes', genes)
                    }
                )
            },
            db() {
                getPathwayList(this.db).then((pathways) => {
                    this.pathways = pathways
                })
            }
        },
        name: "input-pathway-selector-container",
    }
</script>

<style>
    .input-pathway-selector-container {
        font-size: 0.9em;
    }

    .input-pathway-selector-container .field {
        margin-bottom: 0.75em;
    }


    .pathway-selector-instructions {
        font-weight: bold;
    }

    .pathway-search .dropdown-item {
        overflow: visible
    }
</style>