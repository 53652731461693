<template>
    <div class="input-submit-button">
        <b-button expanded rounded type="is-info" size="is-small" @click="submitGenes" :disabled="!geneCount"><strong>Submit {{ geneCount }} genes</strong></b-button>
    </div>
</template>

<script>
    export default {
        name: "input-submit-button",
        computed: {
            geneCount() {
                return this.$store.state.geneInput.length
            }
        },
        methods: {
            submitGenes() {
                this.$store.dispatch('submitGenes')
            }
        }
    }
</script>

<style scoped>

</style>