<template>
    <div class="input-container">
        <input-submit-genes />
        <input-pathway-selector-container />
        <input-submit-button />
        <input-display-data />
        <input-additional-options-container />
        <input-download-options />
        <!--<div class="mssm-logo">-->
            <!--<img class="mssm-logo-img" src="../assets/cptac_logo.png">-->
        <!--</div>-->
    </div>
</template>

<script>
    import InputSubmitGenes from "./InputSubmitGenes";
    import InputAdditionalOptionsContainer from "./InputAdditionalOptionsContainer";
    import InputDisplayData from "./InputDisplayData";
    import InputDownloadOptions from "./InputDownloadOptions";
    import InputPathwaySelectorContainer from "./InputPathwaySelectorContainer";
    import InputSubmitButton from "./InputSubmitButton";

    export default {
        components: {
            InputSubmitButton,
            InputPathwaySelectorContainer,
            InputDownloadOptions,
            InputDisplayData,
            InputAdditionalOptionsContainer,
            InputSubmitGenes
        },
        name: "input-container",
    }
</script>

<style scoped>
    .input-container {
        padding: 8px;
        border: solid 0.1em lightgray;
        border-top: none;
    }

    .input-container > div {
        margin-bottom: 1em;
    }

    .mssm-logo {
        margin: 2em;
    }
</style>