<template>
    <div class="input-download-options">
        <b-button icon-left="file-download-outline" expanded rounded type="is-success is-light" size="is-small" @click="downloadExcel"><strong>Download CSV</strong></b-button>
        <b-button icon-left="download" expanded rounded type="is-primary is-light" size="is-small" @click="downloadImage"><strong>Download SVG image</strong></b-button>
    </div>
</template>

<script>
    import { toSvg } from 'html-to-image';
    import { Parser } from 'json2csv';
    import { getExcel } from "../firebase";

    export default {
        name: "input-download-options",
        computed: {
            replicates() {
                return this.$store.state.replicates
            },
            genes() {
                return this.$store.state.genes
            }
        },
        methods: {
            downloadImage() {
                function filter (node) {
                  return (node.tagName !== 'i');
                }
                toSvg(document.getElementById('heatmap-container-main'), { filter: filter })
                  .then(function (dataUrl) {
                    let dl = document.createElement("a");
                    document.body.appendChild(dl); // This line makes it work in Firefox.
                    dl.setAttribute("href", dataUrl);
                    dl.setAttribute("download", "ptrc-cell-line.svg");
                    dl.click();
                  });
            },
            async downloadExcel() {
                const data = await getExcel({replicates: this.replicates, genes: this.genes})
                const fields = ['Gene symbol', 'Data type', 'Site Position', 'PEA1 S 00Hr', 'PEA1 S 08Hr', 'PEA1 S 24Hr', 'PEA2 R 00Hr', 'PEA2 R 08Hr', 'PEA2 R 24Hr', 'PEO1 S 00Hr', 'PEO1 S 08Hr', 'PEO1 S 24Hr', 'PEO14 S 00Hr', 'PEO14 S 08Hr', 'PEO14 S 24Hr', 'PEO23 R 00Hr', 'PEO23 R 08Hr', 'PEO23 R 24Hr', 'PEO4 R 00Hr', 'PEO4 R 08Hr', 'PEO4 R 24Hr', 'PEO4 R 08-00Hr', 'PEA2 R 08-00Hr', 'PEO14 S 08-00Hr', 'PEO23 R 08-00Hr', 'PEO1 S 08-00Hr', 'PEA1 S 08-00Hr', 'PEO4 R 24-00Hr', 'PEA2 R 24-00Hr', 'PEO14 S 24-00Hr', 'PEO23 R 24-00Hr', 'PEO1 S 24-00Hr', 'PEA1 S 24-00Hr'];
                const opts = { fields };

                try {
                  const parser = new Parser(opts);
                  const csv = parser.parse(data);
                  const blob = new Blob([csv], { type: 'text/csv' });
                  let a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  const url = window.URL.createObjectURL(blob);
                  a.href = url;
                  a.download = "ptrc-cell-line.csv";
                  a.click();
                  window.URL.revokeObjectURL(url)
                } catch (err) {
                  console.error(err);
                }
            }
        }
    }
</script>

<style scoped>
.input-download-options > button {
    margin-bottom: 1em;
}

</style>