<template>
    <div class="legend-container">
      <ul id="legend-categories" class="legend-categories">
        <li v-for="(elements, category) in legend.categories" :key="category">
          <legend-category :category="category" :elements="elements"/>
        </li>
      </ul>
      <ul id="legend-categories-horizontal" class="legend-categories">
        <li v-for="(elements, category) in legend.categoriesHorizontal" :key="category">
          <legend-category-horizontal :category="category" :elements="elements"/>
        </li>
      </ul>
    </div>
</template>

<script>
import LegendCategory from './LegendCategory.vue';
import LegendCategoryHorizontal from './LegendCategoryHorizontal.vue';

import legend from '../legendOptions'

export default {
  name: 'legend-container',
  components: {
    LegendCategory,
    LegendCategoryHorizontal,
  },
  data() {
    return {
        legend
    };
  },
};
</script>

<style scoped>
  .legend-container {
      border-left: solid 0.1em lightgray;
      border-bottom: solid 0.1em lightgray;
  }

  ul {
    margin: 0;
    padding: 10px;
    list-style: none;
  }
</style>
