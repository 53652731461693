<template>
    <div class="input-additional-options-cell-line">
        <section>
        <div class="block">
            <p class="cell-line-label">Cell lines: </p>
            <div v-for="cellLineOption in cellLineOptions" :key="cellLineOption">
                <b-checkbox
                    v-model="cellLine"
                    :native-value="cellLineOption"
                    size="is-small"
                >
                    {{ cellLineOption }}
                </b-checkbox>
            </div>
        </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "input-additional-options-cell-line",
        data() {
            return {
                cellLineOptions: ['PEA1 S/PEA2 R', 'PEO1 S/PEO4 R', 'PEO14 S/PEO23 R',]
            }
        },
        computed: {
            cellLine: {
                get() {
                    return this.$store.state.cellLines
                },
                set(v) {
                    this.$store.dispatch('setCellLines', v)
                }
            }
        }
    }
</script>

<style scoped>
    .input-additional-options-cell-line {
    }

    .cell-line-label {
        font-weight: bold;
        font-size: 0.9em;
    }

</style>