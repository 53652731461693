<template>
    <div id="app">
        <link rel="stylesheet" href="https://cdn.materialdesignicons.com/5.3.45/css/materialdesignicons.min.css">
        <introduction />
        <main class="main">
            <input-container class="input-container-main"/>
            <heatmap-container class="heatmap-container-main" id="heatmap-container-main"/>
            <legend-container class="legend-container-main"/>
        </main>
    </div>
</template>

<script>
import HeatmapContainer from "./components/HeatmapContainer";
import InputContainer from "./components/InputContainer";
import LegendContainer from "./components/LegendContainer";
import Introduction from "./components/Introduction";

export default {
    name: 'App',
    components: {
        Introduction,
        LegendContainer,
        InputContainer,
        HeatmapContainer
    }
}
</script>

<style>
    html {
        box-sizing: border-box;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    .main {
        display: flex;
        flex-direction: row;
    }

    .input-container-main {
        width: 350px;
    }

    .heatmap-container-main {
        width: 90%;
        padding-left: 2em;
        padding-right: 2em;
    }

    .legend-container-main {
        width: 350px;
        padding: 0.8em;
    }

</style>
