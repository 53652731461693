import colorScale from './colorScale'

export default (ctx, isTop=false) => ({
  chart: {
      // width: '100%',
      minWidth: '800px',
      type: 'heatmap',
      animations: {
          enabled: false,
      },
      toolbar: {
          show: false,
      },
      events: {
          dataPointSelection(event, chartContext, config) {
              const {dataPointIndex} = config;
              const {seriesIndex} = config;
              const selectedSeries = chartContext.series.w.config.series[seriesIndex].name;
              const selectedSample = chartContext.series.w.config.series[seriesIndex].data[dataPointIndex].x;
              const selectedValue = chartContext.series.w.config.series[seriesIndex].data[dataPointIndex].value;
              const selectedGene = chartContext.series.w.config.series[seriesIndex].data[dataPointIndex]['gene'];
              const selectedCellLine = chartContext.series.w.config.series[seriesIndex].data[dataPointIndex].cellLine;
              ctx.$store.dispatch(
                'updateDisplayData',
                {
                  selectedSeries,
                  selectedSample,
                  selectedValue,
                  selectedGene,
                    selectedCellLine,
                },
              );
          },
      },
  },
      plotOptions: {
          heatmap: {
              radius: 0,
              padding: 1,
              enableShades: false,
              colorScale,
              borderColor: "#000"
          },
      },
      dataLabels: {
          enabled: false,
      },
      states: {
          hover: {
              filter: {
                  type: 'none',
              },
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'none',
                  value: 0,
              },
          },
      },
      legend: {
          show: false,
      },
      grid: {
          padding: {
              top: 0,
              right: 3,
              bottom: 0,
              left: 6,
          },
      },
  xaxis: {
      labels: {
          // rotate: -90,
          showDuplicates: false,
          show: isTop,
          formatter: function(value) {
            return value
          }
      },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      minWidth: 190,
      offsetY: 100,
    style: {
          colors: 'black',
          // fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 500,
          cssClass: 'apexcharts-yaxis-label',
      },
    },

  },
    tooltip: {
      x: {
      },
      y: {
          show: false,
          formatter: function(value) {return},
          title: {
              formatter: (seriesName) => seriesName,
          },
      },
        marker: {
          show: false,
      },
    },

});

