import firebase from 'firebase/app'
import 'firebase/database'


const firebaseConfig = {
    apiKey: "AIzaSyD9pTQ1wuzCEyDZRfSAG2lNZdNqiz8uX-s",
    authDomain: "protrack-ptrc.firebaseapp.com",
    databaseURL: "https://protrack-ptrc.firebaseio.com",
    projectId: "protrack-ptrc",
    storageBucket: "protrack-ptrc.appspot.com",
    messagingSenderId: "731211910340",
    appId: "1:731211910340:web:2e253c1f6c85a92a8f4d91",
    measurementId: "G-KZL8QW96HV"
};

firebase.initializeApp(firebaseConfig)

export const getSeries = async ({ replicates, gene }) => {
    if (gene.length === 0) {
        return []
    }
    const series = [];
    const paths = [
        `${replicates}/PEA1 S/${gene}`,
        `${replicates}/PEA2 R/${gene}`,
        `${replicates}/PEO1 S/${gene}`,
        `${replicates}/PEO4 R/${gene}`,
        `${replicates}/PEO14 S/${gene}`,
        `${replicates}/PEO23 R/${gene}`,
    ]

    const snapshotPromises = paths.map((path) => {
        const ref = firebase.database().ref(path)
        return ref.once('value')
    })

    const snapshots = await Promise.all(snapshotPromises)
    const values = snapshots.map((snapshot) => { return snapshot.val() })
    if (!values[0]) {
        console.log('empty for : ', gene)
    }
    const emptyObj = {
        'x': '',
        'y': 1000,
        'value': '',
        'gene': '',
        'time': '',
        'cellLine': ''
    }

    for (const idx in values[0]) {
        let idx_arr = [];
        for (const val of values) {
            idx_arr.push(val[idx])
        }
        let idx_obj = {};
        for (const cellLineDataIdx in idx_arr) {
            const cellLineData = idx_arr[cellLineDataIdx]
            if (cellLineDataIdx === '0') {
                idx_obj = {...cellLineData}
            } else {
                // idx_obj.data.push(emptyObj)
                if (cellLineDataIdx % 2 === 0) {
                    // idx_obj.data.push(emptyObj)
                    // idx_obj.data.push(emptyObj)
                }
                idx_obj = {...cellLineData, data: idx_obj.data.concat(cellLineData.data)}
            }
        }
        series.push(idx_obj)
    }
    return series
}

export const getExcel = async ({ replicates, genes }) => {
    const paths = genes.map((gene) => {return `${replicates}/Excel/${gene}` })
    const snapshotPromises = paths.map((path) => {
        const ref = firebase.database().ref(path)
        return ref.once('value')
    })

    const snapshots = await Promise.all(snapshotPromises)
    let excelData = []
    for (const snapshot of snapshots) {
        excelData = excelData.concat(snapshot.val())
    }
    return excelData
}

export const getPathwayList = async (pw_db) => {
    const pw_db_name = pw_db.toLowerCase()
    const path = `PathwayList/${pw_db_name}`
    const ref = firebase.database().ref(path)
    const pathways = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    return pathways
}

export const getPathwayGenes = async (pw_db, pw) => {
    const pw_db_name = pw_db.toLowerCase()
    const path = `Pathway/${pw_db_name}/${pw}`
    console.log('Path: ', path)
    const ref = firebase.database().ref(path)
    const genes = await ref.once('value')
        .then((snapshot) => {
            console.log('snapshot genes?? ', genes)
            return snapshot.val()
        })
    return genes
}
