<template>
    <div class="input-additional-options">
        <section>
        <div class="block">
            <p class="time-label">Time: </p>
            <b-checkbox
                class="time-checkbox"
                v-for="time in timeOptions"
                v-model="times"
                :native-value="time"
                :key="time"
                size="is-small"
            >
                {{ time }}
            </b-checkbox>
        </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "input-additional-options-time",
        data() {
            return {
                timeOptions: ['00Hr', '08Hr', '24Hr', '08-00Hr', '24-00Hr']
            }
        },
        computed: {
            times: {
                get() {
                    return this.$store.state.times
                },
                set(v) {
                    this.$store.dispatch('setTimes', v)
                }
            }
        }
    }
</script>

<style scoped>
    .input-additional-options {
    }

    .time-label {
        font-weight: bold;
        font-size: 0.9em;
    }

    .time-checkbox {
        margin-right: 1em !important;
    }
</style>