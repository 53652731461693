import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cellLines: ['PEA1 S/PEA2 R', 'PEO1 S/PEO4 R', 'PEO14 S/PEO23 R',],
        excelData: {},
        genes: ['BRCA1', 'BRCA2'],
        geneInput: ['BRCA1', 'BRCA2'],
        pathwayDatabase: null,
        replicates: 'Averaged',
        selectedCellLine: '',
        selectedGene: '',
        selectedSample: '',
        selectedSeries: '',
        selectedValue: '',
        times: ['08-00Hr', '24-00Hr'],
    },
    mutations: {
        SET_GENES(state, genes) {
            state.geneInput = genes;
        },
        SET_CELL_LINES(state, cellLines) {
            state.cellLines = [...cellLines]
        },
        SET_PATHWAY_DB(state, pathwayDatabase) {
            state.pathwayDatabase = pathwayDatabase
        },
        SET_REPLICATES(state, replicates) {
            state.replicates = replicates;
        },
        SET_TIMES(state, times) {
            state.times = times;
        },
        SUBMIT_GENES(state) {
            state.genes = state.geneInput.map((el) => { return el.toUpperCase() })
        },
        UPDATE_DISPLAY_DATA(state, { selectedSeries, selectedSample, selectedValue, selectedGene, selectedCellLine}) {
            state.selectedSeries = selectedSeries;
            state.selectedSample = selectedSample;
            state.selectedValue = selectedValue;
            state.selectedGene = selectedGene;
            state.selectedCellLine = selectedCellLine;
        }
    },
    actions: {
        setGenes(store, genes) {
            store.commit('SET_GENES', genes)
        },
        setPathwayDatabase(store, pathwayDatabase) {
            store.commit('SET_PATHWAY_DB', pathwayDatabase)
        },
        setReplicates(store, replicates) {
            store.commit('SET_REPLICATES', replicates)
        },
        setTimes(store, times) {
            store.commit('SET_TIMES', times)
        },
        setCellLines(store, cellLines) {
            store.commit('SET_CELL_LINES', cellLines)
        },
        submitGenes(store) {
            store.commit('SUBMIT_GENES')
        },
        updateDisplayData(store, displayData) {
            store.commit('UPDATE_DISPLAY_DATA', displayData)
        }
    },
    modules: {
    }
})
