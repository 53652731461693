<template>
    <div class="heatmap-gene-container">
        <heatmap-gene v-for="gene in genes" :gene="gene" :key="gene"/>
    </div>
</template>

<script>
    import HeatmapGene from "./HeatmapGene";

    export default {
        components: {HeatmapGene},
        name: "heatmap-gene-container",
        computed: {
            genes() {
                return this.$store.state.genes;
            }
        }
    }
</script>

<style scoped>
.heatmap-gene-container {
}
</style>