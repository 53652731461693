<template>
    <div class="heatmap-container">
        <heatmap-y-axis />
        <heatmap-top />
        <heatmap-gene-container />
    </div>
</template>

<script>
    import HeatmapTop from "./HeatmapTop";
    import HeatmapGeneContainer from "./HeatmapGeneContainer";
    import HeatmapYAxis from "./HeatmapYAxis";

    export default {
        components: {
            HeatmapYAxis,
            HeatmapGeneContainer,
            HeatmapTop
        },
        name: "heatmap-container"
    }
</script>

<style scoped>
    .heatmap-container {

    }
</style>