<template>
    <div class="input-submit-genes">
        <b-field label="Enter newline-separated genes">
            <b-input type="textarea" v-model="geneInput"></b-input>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: "input-submit-genes",
        computed: {
            genes() {
                return this.$store.state.geneInput;
            },
            geneInput: {
                set(v) {
                    this.$store.dispatch(
                        'setGenes',
                        v.split('\n')
                            .filter((s) => {return s.length > 0})
                    )

                },
                get() {
                    return this.genes.join('\n')
                }
            }
        },
    }
</script>

<style>
    .input-submit-genes {
    }

    .label {
        font-size: 0.9em;
    }

    .textarea {
        font-size: 0.85em;
        width: 100%;
    }

    .gene-count {
        text-align: center;
        margin-bottom: 0.75em;
    }

    .field:not(:last-child) {
        margin-bottom: 0;
    }
</style>