<template>
    <div class="input-display-data">
        <p><b>Series: </b> {{ selectedSeries || '--' }}</p>
        <p><b>Sample: </b> {{ selectedSample || '--' }}</p>
        <p><b>Value: </b> {{ String(selectedValue) || '--' }}</p>
        <p><b>Gene: </b> {{ selectedGene || '--' }}</p>
        <p><b>Cell line: </b> {{ selectedCellLine || '--' }}</p>
    </div>
</template>

<script>
    export default {
        name: "input-display-data",
        computed: {
            selectedSeries() {
                return this.$store.state.selectedSeries
            },
            selectedSample() {
                return this.$store.state.selectedSample
            },
            selectedValue() {
                return this.$store.state.selectedValue
            },
            selectedGene() {
                return this.$store.state.selectedGene
            },
            selectedCellLine() {
                return this.$store.state.selectedCellLine
            },
        }
    }
</script>

<style scoped>
.input-display-data {
    font-size: 0.9em;
    background: #e1e1e1;
    padding: 0.5em;
    border-radius: 0.5em;
}
</style>